import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://dashamajig.chibitronics.com/api/fulfillrite',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
});

export default {
    getOrders(page, pageSize, productFilter) {
        return apiClient.get('/orders', {
            params: { page, pageSize, productFilter }
        });
    },
    getProducts(productFilter) {
        return apiClient.get('/products', {
            params: { productFilter }
        });
    }
};
