<template>
  <div id="app" class="background-animation login-container">
    <div v-if="!isLoggedIn" class="login-form">
      <form @submit.prevent="authenticate">
        <input v-model="username" placeholder="Username" class="input-field" />
        <input v-model="password" type="password" placeholder="Password" class="input-field" />
        <button type="submit" class="button-primary">Login</button>
      </form>
      <p v-if="message" class="login-message">{{ message }}</p>
    </div>
    <div v-else>
      <!-- Pass the username prop to MainLobby and listen for the logout event -->
      <main-lobby :username="username" @logout="handleLogout"></main-lobby>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainLobby from './MainLobby.vue'

export default {
  name: 'App',
  components: {
    MainLobby
  },
  data() {
    return {
      username: '',
      password: '',
      message: '',
      isLoggedIn: false
    }
  },
  created() {
    // On load, check if a token and username exist in localStorage
    const token = localStorage.getItem("token")
    const storedUsername = localStorage.getItem("username")
    if (token && storedUsername) {
      this.isLoggedIn = true
      this.username = storedUsername
    }
  },
  methods: {
    authenticate() {
      axios.post('https://dashamajig.chibitronics.com/api/authenticate', {
        username: this.username,
        password: this.password
      })
          .then(response => {
            const token = response.data.token;
            if (token) {
              localStorage.setItem("token", token);
              localStorage.setItem("username", this.username);
              this.isLoggedIn = true;
              this.message = 'Logged in successfully.';
            } else {
              this.message = 'Login failed: no token received.';
            }
          })
          .catch(error => {
            this.message = error.response.data.error;
          });
    },
    handleLogout() {
      // Clear session information and update the login state
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      this.isLoggedIn = false;
      this.username = '';
      this.password = '';
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  height: 100vh;
}
.login-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
}
.login-message {
  margin-top: 20px;
}
</style>
