<template>
  <div class="main-lobby">
    <header class="lobby-header">
      <div class="greeting">Sup {{ username.charAt(0).toUpperCase() + username.slice(1) }}</div>
      <button class="logout-button" @click="$emit('logout')">Log Out</button>
    </header>
    <div class="content-wrapper">
      <h1 class="gradient-text">Welcome to Dashamajig!</h1>
      <h2 class="gradient-text">You are privy to the most exclusive of insights, man.</h2>

      <!-- Tab buttons -->
      <div class="tabs">
        <!-- Show Orders tab only for admin or sales -->
        <button
            v-if="userRole === 'admin' || userRole === 'sales'"
            :class="{ active: activeTab === 'orders' }"
            @click="activeTab = 'orders'">
          Orders
        </button>
        <button
            :class="{ active: activeTab === 'products' }"
            @click="activeTab = 'products'">
          Products
        </button>
      </div>

      <!-- Product filter options (visible only for Products) -->
      <div v-if="activeTab === 'products'" class="filter-section">
        <label>
          <input type="radio" value="active" v-model="productFilter" />
          Active Products
        </label>
        <label>
          <input type="radio" value="all" v-model="productFilter" />
          All Products
        </label>
      </div>

      <!-- Conditionally rendered fetch buttons -->
      <div>
        <button
            v-if="activeTab === 'orders' && (userRole === 'admin' || userRole === 'sales')"
            @click="fetchData"
            class="fetch-button">
          Fetch Orders
        </button>
        <button
            v-if="activeTab === 'products'"
            @click="fetchData"
            class="fetch-button">
          Fetch Products
        </button>
      </div>

      <!-- Orders section (only if user has permission) -->
      <div v-if="activeTab === 'orders' && orders.length > 0 && (userRole === 'admin' || userRole === 'sales')">
        {{ orders.length }} orders loaded
        <input type="text" v-model="searchQuery" placeholder="Search orders..." class="search-bar" />
        <div class="table-container">
          <table class="table">
            <thead>
            <tr>
              <th @click="sortTable('date_added')" class="sortable">Date Placed</th>
              <th @click="sortTable('date_shipped')" class="sortable">Date Shipped</th>
              <th @click="sortTable('order_number')" class="sortable">Order Number</th>
              <th @click="sortTable('shipping_address')" class="sortable">Shipping Address</th>
              <th>Item Details</th>
              <th @click="sortTable('ship_charge_total')" class="sortable">Total Shipping Cost</th>
              <th @click="sortTable('retail_value')" class="sortable">Retail Value</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in filteredOrders" :key="order.id">
              <td>{{ order.date_added }}</td>
              <td>{{ order.date_shipped }}</td>
              <td>{{ order.order_number }}</td>
              <td>{{ order.shipping_address }}</td>
              <td>
                <ul>
                  <li v-for="item in order.items" :key="item.sku">
                    {{ item.quantity }}x {{ item.description }} (SKU: {{ item.sku }}) - ${{ item.retail_value }}
                  </li>
                </ul>
              </td>
              <td>${{ order.ship_charge_total }}</td>
              <td>${{ order.retail_value.toFixed(2) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Products section (available to all) -->
      <div v-if="activeTab === 'products' && products.length > 0">
        {{ products.length }} products loaded
        <input type="text" v-model="searchQuery" placeholder="Search products..." class="search-bar" />
        <div class="table-container products-extra-padding">
          <table class="table">
            <thead>
            <tr>
              <th>SKU</th>
              <th>ProductCat</th>
              <th>Description</th>
              <th>WholesaleValue</th>
              <th>RetailValue</th>
              <th>MaxShelfBoxResolved</th>
              <th>locQuantity</th>
              <th>OnHand</th>
              <th>Available</th>
              <th>Committed</th>
              <th>Pulled</th>
              <th>BackOrdered</th>
              <th>OnAsn</th>
              <th>HasBattery</th>
              <th>CanReceive</th>
              <th>AddDate</th>
              <th>LastReceived</th>
              <th>Approved</th>
              <th>SkuAlias</th>
              <th>Weight</th>
              <th>Length</th>
              <th>Width</th>
              <th>Height</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in filteredProducts" :key="product.sku">
              <td>{{ product.sku }}</td>
              <td>{{ product.product_cat }}</td>
              <td>{{ product.description }}</td>
              <td>${{ product.wholesale_value }}</td>
              <td>${{ product.retail_value }}</td>
              <td>{{ product.max_shelf_box_resolved }}</td>
              <td>{{ product.loc_quantity }}</td>
              <td>{{ product.on_hand }}</td>
              <td>{{ product.available }}</td>
              <td>{{ product.committed }}</td>
              <td>{{ product.pulled }}</td>
              <td>{{ product.back_ordered }}</td>
              <td>{{ product.on_asn }}</td>
              <td>{{ product.has_battery }}</td>
              <td>{{ product.can_receive }}</td>
              <td>{{ formatDate(product.add_date) }}</td>
              <td>{{ formatDate(product.last_received) }}</td>
              <td>{{ product.approved }}</td>
              <td>{{ product.sku_alias }}</td>
              <td>{{ product.weight }}</td>
              <td>{{ product.length }}</td>
              <td>{{ product.width }}</td>
              <td>{{ product.height }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// Simple JWT decoder function using atob to decode Base64URL
function decodeJWT(token) {
  if (!token) return null;
  const parts = token.split('.');
  if (parts.length < 2) return null;
  let payload = parts[1].replace(/-/g, '+').replace(/_/g, '/');
  while (payload.length % 4 !== 0) {
    payload += '=';
  }
  try {
    const decodedStr = atob(payload);
    return JSON.parse(decodedStr);
  } catch (e) {
    console.error('Error decoding JWT:', e);
    return null;
  }
}

import FulfillriteService from '@/services/FulfillriteService';

export default {
  name: 'MainLobby',
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeTab: 'orders',
      orders: [],
      products: [],
      productFilter: 'active',
      currentSortField: 'date_added',
      currentSortDir: -1,
      productSortField: 'sku',
      productSortDir: 1,
      searchQuery: '',
      page: 1,
      pageSize: 100,
      hasMoreOrders: true,
      loadButtonText: 'Fetch Orders',
      loadProductsButtonText: 'Fetch Products'
    };
  },
  computed: {
    // Use our custom JWT decoder to extract the user's role
    userRole() {
      const token = localStorage.getItem("token");
      if (!token) return null;
      const decoded = decodeJWT(token);
      return decoded ? decoded.role : null;
    },
    filteredOrders() {
      if (!this.orders) return [];
      const filtered = this.orders.filter(order => {
        const directMatch = Object.entries(order).some(([key, value]) => {
          if (key === 'items' || value == null) return false;
          return value.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
        });
        const itemMatch = order.items.some(item => {
          return Object.values(item).some(v => {
            if (v == null) return false;
            return v.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
          });
        });
        return directMatch || itemMatch;
      });
      // Sort orders by date_added descending (newest first)
      return filtered.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
    },
    filteredProducts() {
      if (!this.products) return [];
      const filtered = this.products.filter(product => {
        return Object.values(product).some(value => {
          if (value == null) return false;
          return value.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      });
      // Sort products by add_date descending (newest first)
      return filtered.sort((a, b) => new Date(b.add_date) - new Date(a.add_date));
    }
  },
  created() {
    // If the user is standard, force the default active tab to products
    if (this.userRole === 'standard') {
      this.activeTab = 'products';
    }
  },
  methods: {
    fetchData() {
      if (this.activeTab === 'orders') {
        this.fetchOrders();
      } else if (this.activeTab === 'products') {
        this.fetchProducts();
      }
    },
    fetchOrders() {
      if (!this.hasMoreOrders) return;
      FulfillriteService.getOrders(this.page, this.pageSize, this.productFilter)
          .then(response => {
            const newOrders = response.data.map(order => ({
              ...order,
              date_added: order.date_added ? this.formatDate(order.date_added) : 'N/A',
              date_shipped: order.date_shipped ? this.formatDate(order.date_shipped) : 'N/A',
              retail_value: order.items.reduce((total, item) => total + (item.retail_value * item.quantity), 0)
            }));
            const uniqueOrders = newOrders.filter(o => !this.orders.some(e => e.id === o.id));
            if (uniqueOrders.length < this.pageSize) {
              this.hasMoreOrders = false;
              this.loadButtonText = 'No More Orders';
            } else {
              this.loadButtonText = 'Load More';
            }
            this.orders = [...this.orders, ...uniqueOrders];
            this.page += 1;
          })
          .catch(error => {
            console.error('There was an error:', error.response);
          });
    },
    fetchProducts() {
      FulfillriteService.getProducts(this.productFilter)
          .then(response => {
            this.products = response.data;
          })
          .catch(error => {
            console.error('Error fetching products:', error.response);
          });
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A';
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      const year = (date.getFullYear() % 100).toString().padStart(2, '0');
      return `${month}/${day}/${year}`;
    },
    sortTable(field) {
      // Retain manual sort functionality if needed; however, the computed properties enforce sort by addDate.
      if (this.activeTab === 'orders') {
        if (this.currentSortField === field) {
          this.currentSortDir *= -1;
        } else {
          this.currentSortField = field;
          this.currentSortDir = 1;
        }
      }
    }
  }
};
</script>

<style scoped>
.lobby-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}
.greeting {
  margin-right: 20px;
  font-weight: bold;
  color: #fff;
}
.logout-button {
  background: #d346ff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}
.logout-button:hover {
  background: #9147ff;
}
.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  margin: 0;
  padding: 0;
}
</style>
